/* eslint-disable @typescript-eslint/no-explicit-any */

import { uniq } from "lodash";
import type { ProjectResource, ActionTemplateParameterResource, ControlType } from "~/client/resources";
import type { LibraryVariableSetResource } from "~/client/resources/libraryVariableSetResource";
import type { VariableResource, VariableType } from "~/client/resources/variableResource";
import type { VariableSetResource } from "~/client/resources/variableSetResource";
import { repository } from "~/clientInstance";

interface LoadedProjectVariables {
    variableSets: VariableSetResource[];
    libraryVariableSets: LibraryVariableSetResource[];
}

export async function loadProjectVariableNames(projectId: string, variableTypes: VariableType[], controlTypes: ControlType[]) {
    const project = await repository.Projects.get(projectId);
    const variables = await loadVariablesForProject(project);
    return collateProjectVariableNames(project, variables, variableTypes, controlTypes);
}

async function loadVariablesForProject(project: ProjectResource) {
    const libraryVariableSets = await Promise.all(project.IncludedLibraryVariableSetIds.map((x) => repository.LibraryVariableSets.get(x)));
    const variableSetsIds = [project.VariableSetId, ...libraryVariableSets.map((x) => x.VariableSetId)];
    const variableSets = await Promise.all(variableSetsIds.map((x) => repository.Variables.get(x)));

    return { variableSets, libraryVariableSets };
}

function filterVariablesByTypes(variableSets: VariableSetResource[], variableTypes: VariableType[]) {
    const lookup = new Set(variableTypes);
    return variableSets.reduce<VariableResource[]>((prev, current) => [...prev, ...current.Variables], []).filter((x) => lookup.has(x.Type));
}

function collateProjectVariableNames(project: ProjectResource, variables: LoadedProjectVariables, variableTypes: VariableType[], controlTypes: ControlType[]) {
    const variablesByType = filterVariablesByTypes(variables.variableSets, variableTypes);
    const actionTemplates = getAllProjectActionTemplates(project, variables.libraryVariableSets);
    const templateVariables = filterVariableTemplatesByControlTypes(actionTemplates, controlTypes);

    return uniq([...variablesByType.map((x) => x.Name), ...templateVariables.map((x) => x.Name)]).sort();
}

function getAllProjectActionTemplates(project: ProjectResource, libraryVariableSets: LibraryVariableSetResource[]): ActionTemplateParameterResource[] {
    const actionTemplatesFromLibraryVariableSets = libraryVariableSets.reduce<ActionTemplateParameterResource[]>((prev, current) => [...prev, ...current.Templates], []);
    return [...project.Templates, ...actionTemplatesFromLibraryVariableSets];
}

function filterVariableTemplatesByControlTypes(variableTemplates: ActionTemplateParameterResource[], controlTypes: ControlType[]) {
    const lookup = new Set(controlTypes);
    return variableTemplates.filter((template) => (template.DisplaySettings?.["Octopus.ControlType"] ? lookup.has(template.DisplaySettings["Octopus.ControlType"]) : false));
}
