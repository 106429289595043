/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { Callback } from "~/client/SubscriptionRecord";
import { SubscriptionRecord } from "~/client/SubscriptionRecord";
import Caching from "~/client/caching";
import Environment from "~/environment";
import { isRunningInAFunctionalTest } from "~/utils/isRunningInAFunctionalTest";
import { logger } from "~/utils/logging/logger";
import type { AjaxErrorResponseDetails, AjaxRequestDetails, AjaxResponseDetails } from "./ajax";
import Ajax from "./ajax";
import type { RouteArgs } from "./resolver";
import Resolver from "./resolver";
import type { OctopusError, SpaceRootLinks, SpaceRootResource } from "./resources";
import type { GlobalRootLinks, RootResource } from "./resources/rootResource";

const apiLocation = "~/api";

export interface ClientConfiguration {
    serverEndpoint: string;
}

export interface ClientRequestDetails {
    correlationId: number;
    url: string;
    method: string;
}

export interface ClientResponseDetails {
    correlationId: number;
    url: string;
    method: string;
    statusCode: number;
    miniProfileId: string[];
}

export interface ClientErrorResponseDetails extends ClientResponseDetails {
    errorMessage: string;
    errors: string[];
}

export type GlobalAndSpaceRootLinks = keyof GlobalRootLinks | keyof SpaceRootLinks;

// The Octopus Client implements the low-level semantics of the Octopus Deploy REST API
export class Client {
    public static Create(configuration: ClientConfiguration, isAuthenticated: () => boolean, endSession: () => void) {
        logger.info("Creating Octopus client for endpoint: {endpoint}", { endpoint: configuration.serverEndpoint });

        const resolver = new Resolver(configuration.serverEndpoint);
        const clientSession = new ClientSession(new Caching(), isAuthenticated, endSession);
        return new Client(clientSession, resolver, null, null, null);
    }

    requestSubscriptions = new SubscriptionRecord<ClientRequestDetails>();
    responseSubscriptions = new SubscriptionRecord<ClientResponseDetails>();
    errorSubscriptions = new SubscriptionRecord<ClientErrorResponseDetails>();

    onRequestCallback: (details: ClientRequestDetails) => void = undefined!;
    onResponseCallback: (details: ClientResponseDetails) => void = undefined!;
    onErrorResponseCallback: (details: ClientErrorResponseDetails) => void = undefined!;

    private constructor(readonly session: ClientSession, private readonly resolver: Resolver, private rootDocument: RootResource | null, public spaceId: string | null, private spaceRootDocument: SpaceRootResource | null) {
        this.resolver = resolver;
        this.rootDocument = rootDocument;
        this.spaceRootDocument = spaceRootDocument;
    }

    subscribeToRequests = (registrationName: string, callback: Callback<ClientRequestDetails>) => {
        return this.requestSubscriptions.subscribe(registrationName, callback);
    };

    subscribeToResponses = (registrationName: string, callback: Callback<ClientResponseDetails>) => {
        return this.responseSubscriptions.subscribe(registrationName, callback);
    };

    subscribeToErrors = (registrationName: string, callback: (details: ClientErrorResponseDetails) => void) => {
        return this.errorSubscriptions.subscribe(registrationName, callback);
    };

    setOnRequestCallback = (callback: (details: ClientRequestDetails) => void) => {
        this.onRequestCallback = callback;
    };

    setOnResponseCallback = (callback: (details: ClientResponseDetails) => void) => {
        this.onResponseCallback = callback;
    };

    setOnErrorResponseCallback = (callback: (details: ClientErrorResponseDetails) => void) => {
        this.onErrorResponseCallback = callback;
    };

    resolve = (path: string, uriTemplateParameters?: RouteArgs) => this.resolver.resolve(path, uriTemplateParameters);

    connect(progressCallback: (message: string, error?: OctopusError) => void): Promise<void> {
        progressCallback("Checking your credentials. Please wait...");

        return new Promise((resolve, reject) => {
            if (this.rootDocument) {
                resolve();
                return;
            }

            const attempt = (success: any, fail: any) => {
                this.get(apiLocation).then((root) => {
                    success(root);
                }, fail);
            };

            const onSuccess = (root: RootResource) => {
                this.rootDocument = root;
                resolve();
            };

            let fails = 0;
            const onFail = (err: any) => {
                if (err.StatusCode !== 503 && fails < 20) {
                    fails++;
                }

                const timeout = fails === 20 ? 5000 : 1000;

                if ((err.StatusCode === 0 || err.StatusCode === 503) && fails < 20) {
                    if (err.StatusCode === 503) {
                        progressCallback("Octopus Server unavailable.", err);
                    } else if (err.StatusCode === 0) {
                        progressCallback("The Octopus Server does not appear to have started, trying again...", err);
                    }
                } else {
                    progressCallback("Unable to connect to the Octopus Server. Is your server online?", err);
                }
                setTimeout(() => {
                    attempt(onSuccess, onFail);
                }, timeout);
            };

            attempt(onSuccess, onFail);
        });
    }

    disconnect() {
        this.rootDocument = null;
        this.spaceId = null;
        this.spaceRootDocument = null;
    }

    async forSpace(spaceId: string): Promise<Client> {
        const spaceRootResource = await this.get<SpaceRootResource>(this.rootDocument!.Links["SpaceHome"], { spaceId });
        return new Client(this.session, this.resolver, this.rootDocument, spaceId, spaceRootResource);
    }

    forSystem(): Client {
        return new Client(this.session, this.resolver, this.rootDocument, null, null);
    }

    async switchToSpace(spaceId: string): Promise<void> {
        this.spaceId = spaceId;
        this.spaceRootDocument = await this.get<SpaceRootResource>(this.rootDocument!.Links["SpaceHome"], { spaceId: this.spaceId });
    }

    switchToSystem(): void {
        this.spaceId = null;
        this.spaceRootDocument = null;
    }

    get<TResource>(path: string, args?: RouteArgs): Promise<TResource> {
        const url = this.resolveUrlWithSpaceId(path, args);
        return this.dispatchRequest("GET", url) as Promise<TResource>;
    }

    getRaw(path: string, args?: RouteArgs): Promise<string> {
        const url = this.resolve(path, args);

        return new Promise((resolve, reject) => {
            new Ajax({
                session: this.session,
                method: "GET",
                error: (e) => reject(e),
                url,
                raw: true,
                success: (data) => resolve(data),
                tryGetServerInformation: () => this.tryGetServerInformation()!,
                getAntiForgeryTokenCallback: () => this.getAntiforgeryToken()!,
                onRequestCallback: (r) => this.onAjaxRequest(r),
                onResponseCallback: (r) => this.onAjaxResponse(r),
                onErrorResponseCallback: (r) => this.onAjaxErrorResponse(r),
            }).execute();
        });
    }

    onAjaxRequest(ajaxDetails: AjaxRequestDetails) {
        const details = {
            correlationId: ajaxDetails.correlationId,
            url: ajaxDetails.url,
            method: ajaxDetails.method,
        };

        if (this.onRequestCallback) {
            this.onRequestCallback(details);
        }

        this.requestSubscriptions.notifyAll(details);
    }

    onAjaxResponse(ajaxDetails: AjaxResponseDetails) {
        const profilerIds = ajaxDetails.request.getResponseHeader("X-MiniProfiler-Ids");

        const details = {
            correlationId: ajaxDetails.correlationId,
            url: ajaxDetails.url,
            method: ajaxDetails.method,
            statusCode: ajaxDetails.statusCode,
            miniProfileId: profilerIds ? JSON.parse(profilerIds) : [],
        };

        if (this.onResponseCallback) {
            this.onResponseCallback(details);
        }
        this.responseSubscriptions.notifyAll(details);
    }

    onAjaxErrorResponse(ajaxDetails: AjaxErrorResponseDetails) {
        const profilerIds = ajaxDetails.request.getResponseHeader("X-MiniProfiler-Ids");

        const details = {
            correlationId: ajaxDetails.correlationId,
            url: ajaxDetails.url,
            method: ajaxDetails.method,
            statusCode: ajaxDetails.statusCode,
            errorMessage: ajaxDetails.errorMessage,
            errors: ajaxDetails.errors,
            miniProfileId: profilerIds ? JSON.parse(profilerIds) : [],
        };

        if (this.onErrorResponseCallback) {
            this.onErrorResponseCallback(details);
        }

        this.errorSubscriptions.notifyAll(details);
    }

    post<TReturn>(path: string, resource?: any, args?: RouteArgs): Promise<TReturn> {
        const url = this.resolveUrlWithSpaceId(path, args);
        return this.dispatchRequest("POST", url, resource) as Promise<TReturn>;
    }

    create<TNewResource, TResource>(path: string, resource: TNewResource, args: RouteArgs): Promise<TResource> {
        const url = this.resolve(path, args);
        return new Promise((resolve, reject) => {
            this.dispatchRequest("POST", url, resource).then((result: any) => {
                const selfLink = result.Links?.Self;
                if (selfLink) {
                    const result2 = this.get<TResource>(selfLink);
                    resolve(result2);
                    return;
                }
                resolve(result);
            }, reject);
        });
    }

    update<TResource>(path: string, resource: TResource, args?: RouteArgs): Promise<TResource> {
        const url = this.resolve(path, args);
        return new Promise((resolve, reject) => {
            this.dispatchRequest("PUT", url, resource).then((result: any) => {
                const selfLink = result.Links?.Self;
                if (selfLink) {
                    const result2 = this.get<TResource>(selfLink);
                    resolve(result2);
                    return;
                }
                resolve(result);
            }, reject);
        });
    }

    del(path: string, resource?: any, args?: RouteArgs) {
        const url = this.resolve(path, args);
        return this.dispatchRequest("DELETE", url, resource);
    }

    put<TResource>(path: string, resource?: TResource, args?: RouteArgs): Promise<TResource> {
        const url = this.resolveUrlWithSpaceId(path, args);
        return this.dispatchRequest("PUT", url, resource) as Promise<TResource>;
    }

    getAntiforgeryToken() {
        if (!this.isConnected()) {
            return null;
        }

        const installationId = this.getGlobalRootDocument()!.InstallationId;
        if (!installationId) {
            return null;
        }

        // If we have come this far we know we are on a version of Octopus Server which supports anti-forgery tokens
        const antiforgeryCookieName = "Octopus-Csrf-Token_" + installationId;
        const antiforgeryCookies = document.cookie
            .split(";")
            .filter((c) => {
                return c.trim().indexOf(antiforgeryCookieName) === 0;
            })
            .map((c) => {
                return c.trim();
            });

        if (antiforgeryCookies && antiforgeryCookies.length === 1) {
            const antiforgeryToken = antiforgeryCookies[0].split("=")[1];
            return antiforgeryToken;
        } else {
            if (Environment.isInDevelopmentMode()) {
                return "FAKE TOKEN USED FOR DEVELOPMENT";
            }
            return null;
        }
    }

    resolveLinkTemplate(link: GlobalAndSpaceRootLinks, args: any) {
        return this.resolve(this.getLink(link), args);
    }

    getServerInformation() {
        if (!this.isConnected()) {
            throw new Error("The Octopus Client has not connected. THIS SHOULD NOT HAPPEN! Please notify support.");
        }
        return {
            version: this.rootDocument!.Version,
        };
    }

    tryGetServerInformation() {
        return this.rootDocument
            ? {
                  version: this.rootDocument.Version,
                  installationId: this.rootDocument.InstallationId,
              }
            : null;
    }

    throwIfClientNotConnected() {
        if (!this.isConnected()) {
            const extraContextForFunctionalTests =
                " In your Functional Test, use `setupGlobals.connectClient()` to connect the client, or call `setupGlobals.all(...)`." +
                " For more information, see https://github.com/OctopusDeploy/OctopusDeploy/blob/master/frontend/docs/functional_tests.md#globalambient-context";
            const errorMessage = `Can't get the link for ${name} from the client, because the client has not yet been connected.${isRunningInAFunctionalTest() ? extraContextForFunctionalTests : ""}`;
            throw new Error(errorMessage);
        }
    }

    getSystemLink<T>(linkGetter: (links: GlobalRootLinks) => T): T {
        this.throwIfClientNotConnected();
        const link = linkGetter(this.rootDocument!.Links);
        if (link === null) {
            const errorMessage = `Can't get the link for ${name} from the client, because it could not be found in the root document.`;
            throw new Error(errorMessage);
        }
        return link;
    }

    getLink(name: GlobalAndSpaceRootLinks): string {
        this.throwIfClientNotConnected();
        const spaceLinkExists = this.spaceRootDocument && this.spaceRootDocument.Links[name];
        const link = spaceLinkExists ? this.spaceRootDocument!.Links[name] : this.rootDocument!.Links[name];
        if (!link) {
            const extraContextForFunctionalTests =
                " It is likely that you are trying to retrieve a space-scoped link, but no space context has been configured yet." +
                " In your Functional Test, use `setupGlobals.setupSpaceContext(space)` to set up the client's space context, or call `setupGlobals.all(space)`." +
                " For more information, see https://github.com/OctopusDeploy/OctopusDeploy/blob/master/frontend/docs/functional_tests.md#globalambient-context";
            const errorMessage = `Can't get the link for ${name} from the client, because it could not be found in the root document or the space root document.${isRunningInAFunctionalTest() ? extraContextForFunctionalTests : ""}`;
            throw new Error(errorMessage);
        }
        return link;
    }

    private dispatchRequest(method: any, url: string, requestBody?: any) {
        return new Promise((resolve, reject) => {
            new Ajax({
                session: this.session,
                error: (e) => reject(e),
                method,
                url,
                requestBody,
                success: (data) => resolve(data),
                tryGetServerInformation: () => this.tryGetServerInformation()!,
                getAntiForgeryTokenCallback: () => this.getAntiforgeryToken()!,
                onRequestCallback: (r) => this.onAjaxRequest(r),
                onResponseCallback: (r) => this.onAjaxResponse(r),
                onErrorResponseCallback: (r) => this.onAjaxErrorResponse(r),
            }).execute();
        });
    }

    isConnected() {
        return this.rootDocument !== null;
    }

    private getArgsWithSpaceId(args: RouteArgs) {
        return this.spaceId ? { spaceId: this.spaceId, ...args } : args;
    }

    private getGlobalRootDocument() {
        if (!this.isConnected()) {
            throw new Error("The Octopus Client has not connected.");
        }

        return this.rootDocument;
    }

    resolveUrlWithSpaceId(path: string, args?: RouteArgs): string {
        return this.resolve(path, this.getArgsWithSpaceId(args!));
    }
}

export class ClientSession {
    constructor(readonly cache: Caching, readonly isAuthenticated: () => boolean, private readonly endSession: () => void) {}
    end = () => {
        this.endSession();
        this.cache.clearAll();
    };
}
