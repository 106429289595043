/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { ChannelResource, DeploymentPromotionTarget, ProjectResource } from "~/client/resources";
import type { DeploymentPreviewRequests, DeploymentPreviewResource } from "~/client/resources/deploymentPreviewResource";
import type { VariableSetResource } from "~/client/resources/variableSetResource";
import type { Client } from "../client";
import type DeploymentResource from "../resources/deploymentResource";
import type DeploymentTemplateResource from "../resources/deploymentTemplateResource";
import type LatestReleaseResource from "../resources/latestReleaseResource";
import type LifecycleProgressionResource from "../resources/lifecycleProgressionResource";
import type { ReleaseResource } from "../resources/releaseResource";
import type ResourceCollection from "../resources/resourceCollection";
import type { ListArgs } from "./basicRepository";
import BasicRepository from "./basicRepository";

type GetDeploymentArgs = ListArgs;

class ReleasesRepository extends BasicRepository<ReleaseResource, ReleaseResource> {
    constructor(client: Client) {
        super("Releases", client);
    }
    getDeployments(release: ReleaseResource, options?: GetDeploymentArgs): Promise<ResourceCollection<DeploymentResource>> {
        return this.client.get(release.Links["Deployments"], options);
    }
    getDeploymentTemplate(release: ReleaseResource): Promise<DeploymentTemplateResource> {
        return this.client.get(release.Links["DeploymentTemplate"]) as Promise<DeploymentTemplateResource>;
    }
    getDeploymentPreview(promotionTarget: DeploymentPromotionTarget) {
        return this.client.get<DeploymentPreviewResource>(promotionTarget.Links["Preview"], { includeDisabledSteps: true });
    }
    progression(release: ReleaseResource): Promise<LifecycleProgressionResource> {
        return this.client.get(release.Links["Progression"]);
    }
    snapshotVariables(release: ReleaseResource): Promise<ReleaseResource> {
        return this.client.post(release.Links["SnapshotVariables"]);
    }
    variables(release: ReleaseResource): Promise<VariableSetResource[]> {
        return this.client.get(release.Links["Variables"]);
    }
    deploymentPreviews(release: ReleaseResource, deploymentTemplates: DeploymentPreviewRequests): Promise<DeploymentPreviewResource[]> {
        return this.client.post(release.Links["DeploymentPreviews"], deploymentTemplates);
    }
    getChannel(release: ReleaseResource): Promise<ChannelResource> {
        return this.client.get(release.Links["Channel"]);
    }
    getLatest(project: ProjectResource): Promise<LatestReleaseResource[]> {
        return this.client.get<LatestReleaseResource[]>("/bff/spaces/{spaceId}/releases/latest/{projectId}", {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
    }
}

export default ReleasesRepository;
