import React from "react";
import { ControlType } from "~/client/resources";
import { VariableType } from "~/client/resources/variableResource";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type { VariableSelectProps } from "~/components/form/VariableSelect/VariableSelect";
import { VariableSelect } from "~/components/form/VariableSelect/VariableSelect";
import { loadProjectVariableNames } from "~/utils/LoadProjectVariables/loadProjectVariables";

type AccountVariableSelectProps = Omit<VariableSelectProps, "fetchVariables">;

function AwsAccountVariableSelect(props: AccountVariableSelectProps) {
    return <VariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, [VariableType.AmazonWebServicesAccount], [ControlType.AmazonWebServicesAccount])} {...props} />;
}

function AzureAccountVariableSelect(props: AccountVariableSelectProps) {
    return <VariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, [VariableType.AzureAccount], [ControlType.AzureAccount])} {...props} />;
}

export function GoogleCloudAccountVariableSelect(props: AccountVariableSelectProps) {
    return <VariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, [VariableType.GoogleCloudAccount], [ControlType.GoogleCloudAccount])} {...props} />;
}

export const AwsBoundAccountVariableSelect = withBoundField(AwsAccountVariableSelect);
export const AzureBoundAccountVariableSelect = withBoundField(AzureAccountVariableSelect);
